const documentToPlainTextString = require('@contentful/rich-text-plain-text-renderer').documentToPlainTextString;

const excerptFromNode = ({ excerpt, body, richBody }) => {
  const fallback = 'This post does not have a valid excerpt';
  let val = '';
  if (body && body.childMarkdownRemark) val = body.childMarkdownRemark.excerpt;
  if (excerpt) val = excerpt.excerpt;
  if (richBody) {
    const pruneLength = 160;
    const docString = documentToPlainTextString(JSON.parse(richBody.raw));
    if (docString?.length > pruneLength) {
      val = docString.substring(0, pruneLength) + '...';
    }
  }
  val = val.replace(/\{\{.*\}\}/gi, '').trim();
  return val || fallback;
};

module.exports = excerptFromNode;
